
import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import HomeHeader from "@/components/HomeHeader.vue";
import HomeFooter from "@/components/HomeFooter.vue";

@Component({
  components: {
    HomeHeader,
    HomeFooter,
  },
})
export default class Home extends Vue {
  electricalConsumption = 0;
  waterConsumption = 0;
  naturalGasConsumption = 0;

  firstDayOfYear() {
    const now = new Date();
    return (
      new Date(now.getFullYear(), 0, 1).toISOString().split("T")[0] +
      "T00:00:00.000Z"
    );
  }

  currentDay() {
    const now = new Date();
    return now.toISOString().split(".")[0] + ".000Z";
  }

  get apiURL() {
    return process.env.VUE_APP_API_URL;
  }

  async fetchConsumptionData() {
    try {
      const currentDay = this.currentDay();
      const firstDayOfYear = this.firstDayOfYear();
      const username = process.env.VUE_APP_API_USERNAME;
      const password = process.env.VUE_APP_API_PASSWORD;

      const apiURL = this.apiURL;

      const electricalApiUrl = `${apiURL}/api/history?id=AllMeters_ElectricConsumption_Total&start=${firstDayOfYear}&end=${currentDay}`;
      const waterApiUrl = `${apiURL}/api/history?id=AllMeters_Total_WaterFlow&start=${firstDayOfYear}&end=${currentDay}`;
      const naturalGasApiUrl = `${apiURL}/api/history?id=GasMeter_Total_Flow&start=${firstDayOfYear}&end=${currentDay}`;

      const electricalResponse = await axios.get(electricalApiUrl, {
        auth: {
          username: username,
          password: password,
        },
        withCredentials: false,
      });

      const waterResponse = await axios.get(waterApiUrl, {
        auth: {
          username: username,
          password: password,
        },
        withCredentials: false,
      });

      const naturalGasResponse = await axios.get(naturalGasApiUrl, {
        auth: {
          username: username,
          password: password,
        },
        withCredentials: false,
      });

      let electricalData = null;
      let waterData = null;
      let naturalGasData = null;

      if (typeof electricalResponse.data === "string") {
        electricalData = JSON.parse(
          electricalResponse.data.replace(/,(\s*])$/, "$1")
        );
      } else {
        electricalData = electricalResponse.data;
      }

      if (typeof waterResponse.data === "string") {
        waterData = JSON.parse(waterResponse.data.replace(/,(\s*])$/, "$1"));
      } else {
        waterData = waterResponse.data;
      }

      if (typeof naturalGasResponse.data === "string") {
        naturalGasData = JSON.parse(
          naturalGasResponse.data.replace(/,(\s*])$/, "$1")
        );
      } else {
        naturalGasData = naturalGasResponse.data;
      }

      if (electricalData.length > 0) {
        this.electricalConsumption = electricalData[0].value
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      if (waterData.length > 0) {
        this.waterConsumption = waterData[0].value
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      if (naturalGasData.length > 0) {
        this.naturalGasConsumption = naturalGasData[0].value
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    } catch (error) {
      console.error("Error fetching consumption data:", error);
      console.log(this.currentDay());
    }
  }

  mounted() {
    window.scrollTo(0, 0);
  }

  created() {
    this.fetchConsumptionData();
  }

  data() {
    return {
      electricalConsumption: this.electricalConsumption,
      waterConsumption: this.waterConsumption,
      naturalGasConsumption: this.naturalGasConsumption,
    };
  }
}
